import { cn } from '@rouvydev/web-components/utils'

export function Gradient({ className }: { className?: string }) {
  return (
    <div className={cn('absolute inset-0 -z-10 overflow-hidden', className)}>
      {[
        {
          height: '4291px',
          top: '0',
          backgroundImage:
            'radial-gradient(68.59% 42.96% at 59.48% 51.67%, rgba(111, 16, 231, 0.28) 0%, rgba(124, 16, 231, 0) 100%)',
        },
        {
          height: '4291px',
          top: '1201px',
          backgroundImage:
            'adial-gradient(205.26% 69.95% at -113.52% 110.03%, rgba(111, 16, 231, 0.28) 0%, rgba(124, 16, 231, 0) 100%)',
        },
        {
          height: '3737px',
          top: '0',
          backgroundImage:
            'radial-gradient(78.44% 67.23% at 61.9% -39.19%, rgba(79, 170, 255, 0.4) 0%, rgba(79, 118, 255, 0) 100%)',
        },
        {
          height: '4373px',
          top: '1119px',
          backgroundImage:
            'radial-gradient(207.19% 86.66% at 226.85% 141.09%, rgba(79, 170, 255, 0.4) 0%, rgba(79, 118, 255, 0) 100%)',
        },
        {
          height: '3737px',
          top: '0',
          backgroundImage:
            'radial-gradient(84.79% 53.55% at 163.39% 58.83%, rgba(200, 44, 245, 0.4) 0%, rgba(205, 44, 245, 0) 100%)',
        },
      ].map(gradient => (
        <div
          className="absolute inset-0"
          key={gradient.backgroundImage}
          style={{
            ...gradient,
            transform: 'matrix(-1, 0, 0, 1, 0, 0)',
            mixBlendMode: 'normal',
          }}
        />
      ))}
    </div>
  )
}

export function Gradient2({ className }: { className?: string }) {
  return (
    <div className={cn('absolute inset-0 overflow-hidden', className)}>
      {[
        {
          height: '4291px',
          top: '0',
          backgroundImage:
            'radial-gradient(68.59% 42.96% at 59.48% 51.67%, rgba(111, 16, 231, 0.28) 0%, rgba(124, 16, 231, 0) 100%)',
        },
        {
          top: '1201px',
          height: '4291px',
          backgroundImage:
            'radial-gradient(205.26% 69.95% at -113.52% 110.03%, rgba(111, 16, 231, 0.28) 0%, rgba(124, 16, 231, 0) 100%)',
        },
        {
          top: '1119px',
          height: '4373px',
          backgroundImage:
            'radial-gradient(207.19% 86.66% at 226.85% 141.09%, rgba(79, 170, 255, 0.4) 0%, rgba(79, 118, 255, 0) 100%)',
        },
        {
          top: 0,
          height: '3737px',
          backgroundImage:
            'radial-gradient(84.79% 53.55% at 163.39% 58.83%, rgba(200, 44, 245, 0.4) 0%, rgba(205, 44, 245, 0) 100%)',
        },
      ].map(gradient => (
        <div
          className="absolute inset-0"
          key={gradient.backgroundImage}
          style={{
            ...gradient,
            transform: 'matrix(-1, 0, 0, 1, 0, 0)',
            mixBlendMode: 'normal',
          }}
        />
      ))}
    </div>
  )
}

// blog, press header
export function Gradient3({ className }: { className?: string }) {
  return (
    <div className={cn('absolute inset-0 overflow-hidden', className)}>
      {[
        {
          top: '0',
          height: '4291px',
          backgroundImage:
            'radial-gradient(79.27% 34.83% at -13.72% -11.85%, rgba(111, 16, 231, 0.28) 0%, rgba(124, 16, 231, 0) 100%)',
        },
        {
          top: '0',
          height: '3737px',
          backgroundImage:
            'radial-gradient(60.61% 64.99% at 39.61% -36.75%, rgba(79, 170, 255, 0.4) 0%, rgba(79, 118, 255, 0) 100%)',
        },
        {
          top: '0',
          height: '3737px',
          backgroundImage:
            'radial-gradient(90.52% 42.08% at 145.6% 47.71%, rgba(200, 44, 245, 0.4) 0%, rgba(205, 44, 245, 0) 100%)',
        },
      ].map(gradient => (
        <div
          className="absolute inset-0"
          key={gradient.backgroundImage}
          style={{
            ...gradient,
            transform: 'matrix(-1, 0, 0, 1, 0, 0)',
            mixBlendMode: 'normal',
          }}
        />
      ))}
    </div>
  )
}

export function Gradient4({ className }: { className?: string }) {
  return (
    <div className={cn('absolute inset-0 overflow-hidden', className)}>
      {[
        {
          top: '0',
          height: '4291px',
          backgroundImage:
            'radial-gradient(115.05% 34.86% at -22.63% 18.84%, rgba(111, 16, 231, 0.28) 0%, rgba(124, 16, 231, 0) 100%)',
        },
        {
          top: '0',
          height: '3737px',
          backgroundImage:
            'radial-gradient(140.63% 53.37% at 164.01% -31%, rgba(79, 170, 255, 0.4) 0%, rgba(79, 118, 255, 0) 100%)',
        },
      ].map(gradient => (
        <div
          className="absolute inset-0"
          key={gradient.backgroundImage}
          style={{
            ...gradient,
            transform: 'matrix(-1, 0, 0, 1, 0, 0)',
            mixBlendMode: 'normal',
          }}
        />
      ))}
    </div>
  )
}

export function Gradient5({ className }: { className?: string }) {
  return (
    <div
      className={cn(
        'absolute bottom-0 left-0 right-0 h-[1080px] w-full overflow-hidden',
        className,
      )}
    >
      {[
        {
          backgroundImage:
            'radial-gradient(34.28% 218.15% at 17.82% -98.61%, rgba(79, 170, 255, 0.4) 0%, rgba(79, 170, 255, 0) 100%)',
        },
        {
          backgroundImage:
            'radial-gradient(42.63% 195.37% at 53.88% -56.39%, rgba(111, 16, 231, 0.4) 0%, rgba(124, 16, 231, 0) 100%)',
        },
      ].map(gradient => (
        <div
          className="absolute inset-0"
          key={gradient.backgroundImage}
          style={{
            ...gradient,
            mixBlendMode: 'normal',
            transform: 'matrix(-1, 0, 0, 1, 0, 0)',
          }}
        />
      ))}
    </div>
  )
}
