import React from 'react'
import { HAlign, ThumborUrlBuilder, VAlign } from 'thumbor-url-builder-ts'

const thumbor = new ThumborUrlBuilder(ENV.THUMBOR_SECURITY, ENV.THUMBOR_URL)

type ImageFormat = {
  url: string
  width: number
  height: number
  optimizedUrl?: string
  optimizedWidth?: number
  optimizedHeight?: number
}

type UploadFormat = {
  large?: ImageFormat
  medium?: ImageFormat
  small?: ImageFormat
  thumbnail?: ImageFormat
}

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  hAlign?: HAlign
  vAlign?: VAlign
  formats?: unknown
}

export function Image({
  src,
  className,
  width,
  height,
  alt,
  loading = 'lazy',
  hAlign = HAlign.CENTER,
  vAlign = VAlign.MIDDLE,
  formats = {},
}: ImageProps) {
  function createBuilder(
    url: URL,
    width?: number,
    height?: number,
    hAlign: HAlign = HAlign.CENTER,
    vAlign: VAlign = VAlign.MIDDLE,
  ) {
    const builder = thumbor.setImagePath(encodeURIComponent(url.toString()))

    if (width !== undefined && height !== undefined) {
      builder.hAlign(hAlign).vAlign(vAlign).resize(width, height)
    }

    return builder
  }

  let resultSrc = src

  const imageFormats = formats as UploadFormat

  imageFormats &&
    Object.keys(imageFormats).forEach(formatKey => {
      try {
        const format = imageFormats[formatKey as keyof UploadFormat]

        if (!format || !format.url) {
          return
        }

        const requestedUrl = new URL(format?.url ?? '')

        let cropWidth = 0
        let cropHeight = 0

        const imgWidth = Number(width) || 0
        const imgHeight = Number(height) || 0

        if (imgWidth > 0 && imgHeight > 0) {
          const resultingAspectRatio = imgWidth / imgHeight
          const formatAspectRatio = format.width / format.height

          if (resultingAspectRatio > formatAspectRatio) {
            cropHeight = format.height
            cropWidth =
              Math.round(cropHeight * resultingAspectRatio) > format.width
                ? format.width
                : Math.round(cropHeight * resultingAspectRatio)
          } else {
            cropWidth = format.width
            cropHeight =
              Math.round(cropWidth / resultingAspectRatio) > format.height
                ? format.height
                : Math.round(cropWidth / resultingAspectRatio)
          }
        }

        format.optimizedUrl = createBuilder(
          requestedUrl,
          cropWidth === format.width ? undefined : cropWidth,
          cropHeight === format.height ? undefined : cropHeight,
        ).buildUrl()

        format.optimizedWidth = cropWidth ? cropWidth : format.width
        format.optimizedHeight = cropHeight ? cropWidth : format.height
      } catch (_) {}
    })

  try {
    const requestedUrl = new URL(src ?? '')

    resultSrc = createBuilder(
      requestedUrl,
      Number(width) ?? 0,
      Number(height) ?? 0,
      hAlign,
      vAlign,
    ).buildUrl()
  } catch (_) {}

  function resolveDimension(dimension: string | number | undefined) {
    return !dimension || dimension === 0 ? undefined : dimension
  }

  return (
    <>
      <picture>
        {imageFormats?.thumbnail?.optimizedUrl && (
          <source
            srcSet={`${imageFormats.thumbnail.optimizedUrl} 125w`}
            media={`(max-width: 125px)`}
            width={resolveDimension(imageFormats.thumbnail.optimizedWidth)}
            height={resolveDimension(imageFormats.thumbnail.optimizedHeight)}
          />
        )}
        {imageFormats?.small?.optimizedUrl && (
          <source
            srcSet={`${imageFormats.small.optimizedUrl} 250w`}
            media={`(max-width: 250px)`}
            width={resolveDimension(imageFormats.small.optimizedWidth)}
            height={resolveDimension(imageFormats.small.optimizedHeight)}
          />
        )}
        {imageFormats?.medium?.optimizedUrl && (
          <source
            srcSet={`${imageFormats.medium.optimizedUrl} 375w`}
            media={`(max-width: 375px)`}
            width={resolveDimension(imageFormats.medium.optimizedWidth)}
            height={resolveDimension(imageFormats.medium.optimizedHeight)}
          />
        )}
        {imageFormats?.large?.optimizedUrl && (
          <source
            srcSet={`${imageFormats.large.optimizedUrl} 500w`}
            media={`(max-width: 500px)`}
            width={resolveDimension(imageFormats.large.optimizedWidth)}
            height={resolveDimension(imageFormats.large.optimizedHeight)}
          />
        )}
        <img
          className={className}
          src={resultSrc}
          alt={alt}
          width={resolveDimension(width)}
          height={resolveDimension(height)}
          loading={loading}
          decoding="auto"
        />
      </picture>
    </>
  )
}
